* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: rgb(201, 227, 255);
}

.top-nav {
  width: 100%;
  background-color: rgb(53, 68, 241);
}

.icons-top {
  color: black;
  text-decoration: none;
}

.top-element a:hover {
  color: yellow;
}

.top-element {
  margin-right: 3rem;
  color: whitesmoke;
}

.logo {
  width: 6rem;
  height: auto;
}

.icons {
  margin-left: 1rem;
  color: black;
}

.icons:hover {
  color: blue;
  transform: scale(1.5);
}

a .navlink {
  color: black;
  text-decoration: none;
}

.navbar-toggler-icon {
  background-color: blue;
}

#collasible-nav-dropdown {
  color: black;
}

#collasible-nav-dropdown:hover {
  color: blue;
}

.title {
  text-transform: uppercase;
  position: relative;
  bottom: 25rem;
  color: rgb(255, 255, 255);
  text-shadow: 2px 2px 2px #818181;
}

.content {
  color: red;
}

.heading {
  margin-top: 3rem;
  padding-top: 2rem;
  font-size: xx-large;
  font-weight: bolder;
  text-align: center;
  text-transform: uppercase;
}

.cover {
  font-size: large;
  text-align: center;
}

.intro {
  font-size: large;
}

.carousel-button {
  position: relative;
  bottom: 25rem;
}

.carousel-button a {
  color: white;
  text-decoration: none;
}

.divider {
  width: 25%;
  border: 15px solid white;
  margin-left: auto;
  margin-right: auto;
}

.card {
  width: 18rem;
  height: 24rem;
  text-align: center;
  margin: 1.5rem;
  box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
}

.portfolio {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.service-card {
  width: 26rem;
  height: 25rem;
}

.residencia-card {
  width: 32rem;
  height: 14rem;
}

.video-container {
  display: flex;
  justify-content: center;
}

.contact {
  margin-top: 3rem;
}

.leaflet-container {
  width: 100%;
  height: 49vh;
  margin-top: 3rem;
}

.citas-button a {
  color: white;
  text-decoration: none;
}

.raices {
  margin-left: auto;
  margin-right: auto;
}

.form-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.form-card {
  width: 50rem;
  height: auto;
}

.form-label {
  display: flex;
  justify-content: flex-start;
  margin-top: 1.5rem;
  font-weight: bolder;
}

footer {
  background-color: #898c8c!important;
}

.footer-content {
  margin: 3rem;
  color: white;
}

.copyright {
  display: flex;
  justify-content: center;
  color: whitesmoke;
}

.copyright a {
  margin-left: 5px;
  color: rgb(235, 238, 6);
  text-decoration: none;
}

.copyright a:hover {
  color: rgb(255, 0, 0);
}

@media all and (max-width: 767px) {
  .carousel-slider {
    display: none;
  }

  .card {
    width: 18rem;
    height: 25rem;
    text-align: center;
    margin: 1.5rem;
    box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
      -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
      -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
  }

  .service-card {
    width: 26rem;
    height: 36rem;
  }

  .residencia-card {
    width: 32rem;
    height: 26rem;
  }

  iframe {
    width: 100%;
  }
}

@media all and (min-width: 768px) and (max-width: 991px) {
  .carousel-slider {
    width: 100%;
    height: 40vh;
  }

  .hide {
    display: none;
  }
}
